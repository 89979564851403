.css-8w2k8s-MuiButtonBase-root {
  background: black!important;
  border-radius: 12px !important;
}
.css-1p75ef3-MuiButtonBase-root-MuiButton-root {
  background-color: black!important;
 
  
}
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.css-1rt0ubb-MuiButtonBase-root-MuiButton-root {
  background-color: black !important;
  border-radius: 12px !important;
  margin-left: 36px !important;
  width: 100px !important;
}
.css-1rm05dj-MuiListItem-root{
  background-color: #f4f4f4;
  border-radius: 12px;
  width: 190px !important;
  margin-top: 12px;
}
.css-jz5egs-MuiSvgIcon-root{
  color: #FF8080 !important;
 
}
.css-pwlsy1-MuiButtonBase-root-MuiButton-root{
  background-color: #a1a1a1 !important;
  border-radius: 12px !important;
  width: 100px !important;

  
}
.css-knqc4i-MuiDialogActions-root>:not(style)~:not(style){
  background-color: black !important;
  border-radius: 12px !important;
  margin-left: 24px !important;
  margin-right: 18px !important;
  width: 100px !important;

}
.css-vmia0b-MuiButtonBase-root-MuiButton-root{
  background-color: #f4f4f4 !important;
  border-radius: 12px !important;
  width: 100px !important;

}
.css-1rm05dj-MuiListItem-root{
  background-color: #f4f4f4 !important;
  border-radius: 12px !important;
  margin-left:0px !important;
  margin-bottom: 0px !important;
}

.css-gl757d-MuiButtonBase-roott{
  border-radius: 12px !important;
  background-color: #f4f4f4 !important;
}
.css-10z8il4-MuiTypography-root{
  color: #FF8080 !important;
}
.css-19jcbkz-MuiTypography-root{
  color: #2c2c2c !important;
  margin-right: 20px !important;
  font-weight: 600 !important;

}
.css-et1ao3-MuiTypography-root{
  font-weight: 550 !important;
}
.css-1rm05dj-MuiListItem-root{
  padding-bottom: 0px !important;
}
.css-knqc4i-MuiDialogActions-root>:not(style)~:not(style){
  background-color: #a1a1a1 !important;
  color: #2c2c2c !important;
}
.css-3n1xf1-MuiButtonBase-root-MuiButton-root{
  background-color: #a1a1a1 !important;
  color: #2c2c2c !important; 
  border-radius: 12px !important;
  width: 100px !important;
}
.css-dklc19-MuiButtonBase-root {
  background-color: #444444 !important;
  border-radius: 12px !important;
}
/* .css-1whbsid-MuiTypography-root{
   color: #2c2c2c !important; 
} */
.css-gl757d-MuiButtonBase-root{
  background-color: #444444 !important;
  border-radius: 12px !important;
}
.css-z9rfye-MuiButtonBase-root{
  background-color: #2c2c2c !important;
  border-radius: 12px !important;
}

.css-vmia0b-MuiButtonBase-root-MuiButton-root {
  background-color: #a1a1a1 !important;
}



/* selected count */
.css-1ii6wos {

  color: black !important; 
  font-weight: 400 !important;
  font-size: 0.875rem !important;
}
/* submit button lightmode */
.css-ihx15 {
  background-color: black !important;
  border-radius: 12px !important;
  color: #ffffff !important;
}
.css-ihx15:hover {
  background-color: black !important;
  border-radius: 12px !important;
  color: #ffffff !important;

}
/* cancel button lightmode */
.css-15ejckq:hover {
  background-color: black !important;
  border-radius: 12px !important;
  color: #ffffff !important;

}

.css-15ejckq:hover {
  background-color: black !important;
  border-radius: 12px !important;
  color: #ffffff !important;


}
/* selected */
.css-oa68eo {
  background-color: black !important;
  border-radius: 12px !important;
  color: #ffffff !important;
}
/* dark mode default date */
.css-6zsb0z {
  background-color: #a1a1a1 !important;
  color: #2c2c2c !important; 
  border-radius: 12px !important;
}
/* dark mode selected date */

.css-1f69b7r {
  background-color: #a1a1a1 !important;
  color: #2c2c2c !important; 
  border-radius: 12px !important;
}
/* dark mode count */
.css-1qokniq {
  color: white !important; 
  font-weight: 400 !important;
  font-size: 0.875rem !important;
}
/* light mode default date */
.css-1a51bxz {
  background-color: black !important;
  border-radius: 12px !important;
  color: #ffffff !important;
}
/* dark mode submit button  */

.css-11n07zt {
  border-radius: 12px !important;
  color: #ffffff !important;
}



