/* figcaption {
    /* position: absolute; */
/* bottom: 35rem; */
/* top: 110px;
    left: 50px; */
/* margin: 200 800 rem; */
/* font-family: 'Poppins', sans-serif; 

} */

#somaiya {
  position: absolute;
  width: 13%;
  top: 4%;
  left: 2.8%;
  height: 12%;
}


/* @media screen and (max-width:1500px) {
  html {
    font-size: 100%;
  }
}

@media screen and (max-width:1450px) {
  html {
    font-size: 95%;
  }
}
@media screen and (max-width:1440px) {
  html {
    font-size: 90%;
  }
}
@media screen and (max-width:1400px) {
  html {
    font-size: 85%;
  }
}

@media screen and (max-width:1350px) {
  html {
    font-size: 83%;
  }
}

@media screen and (max-width:1300px) {
  html {
    font-size: 80%;
  }
}

@media screen and (max-width:1250px) {
  html {
    font-size: 77%;
  }
}

@media screen and (max-width:1200px) {
  html {
    font-size: 75%;
  }
}

@media screen and (max-width:1150px) {
  html {
    font-size: 73%;
  }
}

@media screen and (max-width:1100px) {
  html {
    font-size: 70%;
  }
}

@media screen and (max-width:1060px) {
  html {
    font-size: 60%;
  }
}

@media screen and (max-width:1050px) {
  html {
    font-size: 60%;
  }
}

@media screen and (max-width:1000px) {
  html {
    font-size: 60%;
  }
}

@media screen and (max-width:950px) {
  html {
    font-size: 63%;
  }
}

@media screen and (max-width:900px) {
  html {
    font-size: 60%;
  }
}

@media screen and (max-width:850px) {
  html {
    font-size: 57%;
  }
}

@media screen and (max-width:800px) {
  html {
    font-size: 55%;
  }
}

@media screen and (max-width:750px) {
  html {
    font-size: 53%;
  }
}

@media screen and (max-width:700px) {
  html {
    font-size: 50%;
  }
}

@media screen and (max-width:650px) {
  html {
    font-size: 47%;
  }
}

@media screen and (max-width:600px) {
  html {
    font-size: 45%;
  }
} */

#the {
  margin-left: 0.313rem;
}

#top {
  /* margin-bottom: 30px;
    margin-left: 50px; */
  /* font-size: 50px; */
  position: absolute;
  color: azure;
  top: 25%;
  left: 3.5%;
  font-family: Poppins, sans-serif;
  font-size: 3.125rem;
  font-style: normal;
  font-weight: 400;
  /* line-height: 250%; */
}

#bottom {
  /* margin-top: 50px;
    margin-left: 50px; */
  /* font-size: 50px;
    color: azure;
    position: absolute;
    top: 150px;
    left: 50px;
    /* bottom: 30rem;
    margin: 200 800 rem; */
  /* font-family: 'Poppins', sans-serif;  */
  position: absolute;
  color: azure;
  top: 33.2%;
  /* top: 13rem; */
  left: 3.5%;
  font-family: Poppins, sans-serif;
  font-size: 3.125rem;
  font-style: normal;
  font-weight: 400;
  /* line-height: 9rem; */
}

#footer {
  position: absolute;
  bottom: 5%;
  left: 2.4%;
  padding: 0.313rem;
  background: rgba(255, 255, 255, 0.16);
  /* Light Blue with 70% transparency */
  /* width: 27.438rem; */
  width: 28.5%;
  /* height: 6.313rem; */
  height: 14%;
  padding: 1.5rem, 2rem, 1.5rem, 2rem;
  border-radius: 24px;
  /* display: flex;
    padding: 24px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    border-radius: 24px;
    background: rgba(255, 255, 255, 0.16);
    backdrop-filter: blur(6px); */
}

#foot {
  color: #FFF;
  font-family: Poppins, sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 14px */
  margin-left: 5%;
  margin-bottom: 0rem;
  margin-top: 5%;
}

#foot2 {
  color: #FFF;
  font-family: Poppins, sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 1.25rem;
  margin-top: 0.75rem;
}

.image-transition-container {
  position: relative;
}

.image-transition {
  max-width: 100%;
  height: auto;
  transition: opacity 0.5s ease-in-out;
}

/* #email::placeholder {
    margin-left: 0px;
    padding-left: 0px;
  } */

#imgBox {
  display: flex;
  flex-wrap: wrap;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-top: 1.8%;
  /* Assuming it's a pixel value */
  margin-left: 1.8%;
  /* Assuming it's a pixel value */
  width: 98.2%;
  height: 96vh;
  border-radius: 24px;
  /* Uncomment the following lines if needed */
  /* padding: 32px; */
  /* flex-direction: column; */
  /* justify-content: space-between; */
  /* align-items: flex-start; */
  /* flex: 1 0 0; */
  /* align-self: stretch; */
  /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), url(<path-to-image>), lightgray -453.352px 0px / 154.344% 100% no-repeat; */
}

/* #signInContainer {
  width: 100vw;
} */

/* #signInGridItem {
  width: 41.055rem
} */

/* #signInBox {
  margin-left: 8.75rem;
  margin-top: 9.375rem;
  margin-bottom: 9.375rem;
} */

/* #signInTitle {
  font-weight: 400;
  font-family: "Poppins; sans-serif";
  font-style: normal;
  font-size: 2rem;
  line-height: normal;
  color: #2C2C2C;
  margin-bottom: 2.5rem;
} */

/* #emailInputLabel {
  color: "#4A4A4A";
  font-family: "Poppins; sans-serif";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  margin-bottom: 0.813rem;
} */

/* #passwordInputLabel {
  color: #4A4A4A;
  font-family: "Poppins; sans-serif";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  margin-bottom: 0.813rem;
} */

/* #passwordTextField {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  align-self: stretch;
  border-radius: 16px;
  background-color: #F2F2F2; */
  /* padding: 0px 24px;
  paddingX: 24px; */
  /* width: 25rem;
} */

/* #forgotGridContainer {
  width: 25rem;
} */

/* #forgotLink {
  color: #2C2C2C;
  font-family: "Poppins; sans-serif";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 2.5rem;
  
} */

/* #signInButton {
  display: flex;
  width: 25rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 16px;
  background: #2C2C2C;
  color: #FFF;
  text-align: center;
  font-family: "Poppins; sans-serif";
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
} */

/* #signInBox {
  margin-left: '8.75rem';
  margin-top: '9.375rem';
  margin-bottom: '9.375rem';
} */
