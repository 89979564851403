#forecastitle {
    font-family: "Poppins", sans-serif;
    font-size: 1.75rem;
    font-weight: 600;
    line-height: 0.135rem;
    margin-left: 2.5rem;
    margin-top: 2rem;
    margin-bottom: 2.5rem;
}

#rightbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1.063rem;
    align-self: stretch;
    border-radius: 24px;
    box-shadow: 0rem 0.625rem 2.5rem 0rem rgba(209, 209, 209, 0.40);
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
    width: 10rem;
    height: 6.75rem;
}

#loadbox {
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 8px;
    margin-bottom: 0.625rem;
}

#tempicon {
    margin-left: 0.438rem;
    height: 1.563rem;
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
   
}

#tempbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1.063rem;
    align-self: stretch;
    border-radius: 24px;
    margin-top: 1.25rem;
    box-shadow: 0rem 0.625rem 2.5rem 0rem rgba(246, 229, 212, 0.40);
    padding: 1.5rem;
    width: 10rem;
    height: 6.75rem;
}

#temperature {
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 8px;
    margin-bottom: 0.625rem;
}

#light {
    margin-left: 0.563rem;
    height: 1.563rem;
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
}

#humidbox {
    gap: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 1.25rem;
    align-self: stretch;
    border-radius: 24px;
    box-shadow: 0rem 0.625rem 2.5rem 0rem rgba(224, 223, 244, 0.40);
    padding: 1.5rem;
    width: 10rem;
    height: 6.75rem;
}

#humidity {
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 8px;
    margin-bottom: 0.625rem;
}

#drop {
    margin-left: 0.313rem;
    height: 1.563rem;
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
}

#meanabs {
    border-radius: 24px;
    /* or 20px, depending on your preference */
    /* height: 29.375rem; */
    /* width: 74.875rem; */
    padding-bottom: 0px;
    width: 98%;
    margin-top: 1.875rem;
}

#meanerror {
    font-family: "Poppins", sans-serif;
    font-size: 1.75rem;
    font-weight: 600;
    line-height: 2.188rem;
    letter-spacing: 0rem;
    text-align: left;
    margin-left: 1.25rem;
    padding-top: 0.5rem;
}

#startdate {
    margin-right: 0.625rem !important;
    margin-bottom: 2.5rem !important;
    font-size: 0.938rem !important;
    font-family: "Poppins", sans-serif;
    font-weight: 400 !important;
    line-height: 1.225rem !important;
}

#form1 {
    display: flex;
    align-items: center;
    border: none;
}

#localization {
    border-radius: 12px;
    height: 3.125rem;
    width: 14.375rem;
}

#mean1 {
    padding-bottom: 1.25rem;
    margin-top: 1.875rem;
    padding-top: 0rem;
    width: 76.65rem;
}

#adapter {
    border-radius: 12px;
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    height: 3.125rem;
    width: 14.375rem;
}

#fullest {
    display: flex;
    align-items: center;
    border: none;
}

#endate {
    margin-right: 0.625rem;
    margin-bottom: 6.5rem;
    font-size: 0.938rem;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    line-height: 1.225rem;
}

#downloadicon {
    margin-top: 0.625rem;
    margin-left: 0.625rem;
   
}

#explainableai {
    width: 37rem;
    border-radius: 24px;
    height: 29.375rem;
    margin-left: 1.5rem;
    box-shadow: 0rem 0.625rem 2.5rem 0rem rgba(222, 230, 237, 0.4);
}

#typoexplainable {
    margin-left: 1rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    font-family: "Poppins", sans-serif;
    font-size: 1.75rem;
    font-weight: 600;
    line-height: 2.188rem;
    letter-spacing: 0rem;
    text-align: left;
}

#primary {
    font-weight: 400;
    line-height: 1.375rem;
    letter-spacing: 0rem;
    font-size: 1.125rem;
    text-align: left;
    margin-left: 1.75rem;
    margin-top: 1.125rem;
}

#newgrid {
    margin-left: 1.02rem;
    margin-top: 0.5rem;
    width: 33.75rem;

}

#mainGridContainer {
    margin-top: 1.875rem;
    margin-bottom: 0rem;
    width: 76.65rem;
}

#dashboardGraphBox {
    height: 38.75rem;
    width: 62.5rem;
    border-radius: 24px;
}

#avgLoadData {
    margin-bottom: 0.2rem;
    font-size: 1.125rem;
    font-family: Poppins, sans-serif;
}

#avgLoadLabel {
    font-size: 1rem;
    font-family: Poppins, sans-serif;
    margin-right: 1rem;
}

#avgTempData {
    margin-bottom: 0.625rem;
    font-size: 1.125rem;
    font-family: Poppins, sans-serif;
}

#avgTempLabel {
    font-size: 1rem;
    font-family: Poppins, sans-serif;
    margin-right: 1rem;
}

#avgHumData {
    margin-bottom: 0.625rem;
    font-size: 1.125rem;
    font-family: Poppins, sans-serif;
}

#avgHumLabel {
    font-size: 1rem;
    font-family: Poppins, sans-serif;
    margin-right: 1rem;
}

#downloadIconBox {
    width: 2.875rem;
    height: 3.125rem;
    margin-left: 1.125rem;
    border-radius: 0.625rem;
    margin-top: 0rem;
    padding: 0rem;
    margin-top: 0.5rem;

}

#temperaturebox {
    width: 15.8rem;
    height: 2.75rem;
    padding: 1rem, 1.25rem, 1rem, 1.25rem;
    border-radius: 16px;
    justify-content: space-between;
}

#exaiTempLabel {
    font-family: Poppins, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0rem;
    margin-top: 0.75rem;
    margin-left: 0.938rem;
}

#exaiTempArrow {
    margin-left: 6.5rem;
    margin-top: 0.75rem;
    width: 1.25rem;
    height: 1.25rem;
}

#exaiHumBox {
    width: 15.8rem;
    height: 2.75rem;
    padding: 1rem, 1.25rem, 1rem, 1.25rem;
    border-radius: 16px;
    justify-content: space-between;
}

#exaiHumLabel {
    font-family: Poppins, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0rem;
    margin-top: 0.75rem;
    margin-left: 0.938rem;
}

#exaiHumArrow {
    margin-left: 8.75rem;
    margin-top: 0.75rem;
    width: 1.25rem;
    height: 1.25rem;
}

#exaiSpecFactLabel {
    font-family: Poppins, sans-serif;
    font-weight: 400;
    line-height: 1.375rem;
    letter-spacing: 0rem;
    font-size: 1.125rem;
    letter-spacing: 0rem;
    margin-left: 1.5rem;
}

#exaiSpecFactBox {
    width: 8.75rem;
    height: 2.688rem;
    padding: 1rem, 2rem, 1rem, 2rem;
    border-radius: 16px;
    margin-left: 1.08rem;
}

#exaiColdWaveLabel {
    font-family: Poppins, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0rem;
    padding-top: 0.5rem;
    margin-left: 1rem;
}

#exaiDiwBox {
    width: 6.813rem;
    height: 2.688rem;
    padding: 1rem, 2rem, 1rem, 2rem;
    border-radius: 16px;
    gap: 1.25rem;
    margin-left: 1rem;
}

#exaiDiwLLabel {
    font-family: Poppins, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0rem;
    padding-top: 0.5rem;
    margin-left: 2rem;
}

#exaiOutcomeLabel {
    font-family: Poppins, sans-serif;
    font-weight: 400;
    line-height: 1.375rem;
    letter-spacing: 0rem;
    font-size: 1.125rem;
    letter-spacing: 0rem;
    margin-left: 2.4rem;
}

#exaiLoadBox {
    width: 14.041rem;
    height: 2.75rem;
    padding: 1rem, 1.25rem, 1rem, 1.25rem;
    border-radius: 16px;
    justify-content: space-between;
    margin-top: 0.7rem;
    margin-left: 1.8rem;
}

#exaiLoadLabel {
    font-family: Poppins, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0rem;
    margin-top: 0.75rem;
    margin-left: 0.938rem;
}

#exaiLoadArrow {
    margin-left: 9rem;
    margin-top: 0.75rem;
    width: 1.25rem;
    height: 1.25rem;
}

#exaiCompLabel {
    font-family: Poppins, sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.375rem;
    letter-spacing: 0rem;
    margin-left: 1.5rem;
    margin-top: 1.125rem;
}

#exaiCompDetailBox {
    width: 33rem;
    height: 3.125rem;
    border-radius: 16px;
    margin-left: 1rem;
    margin-top: 0.625rem;
}

#exaiCompDetailData {
    font-family: Poppins, sans-serif;
    font-size: 0.969rem;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0rem;
    text-align: left;
    margin-top: 0.25rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
}

#exaiDetExpButton {
    text-transform: none;
    width: 33rem;
    height: 3.25rem;
    padding: 1rem, 2rem, 1rem, 2rem;
    border-radius: 16px;
    margin-top: 2rem;
    margin-left: 1.125rem;
    font-family: Poppins, sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.125rem;
    text-align: center;
}